import { createGlobalStyle } from "styled-components";

import OpenSansBold from "../assets/fonts/opensans-bold-webfont.woff";
import OpenSansBoldItalic from "../assets/fonts/opensans-bolditalic-webfont.woff";
import OpenSansExtraBold from "../assets/fonts/opensans-extrabold-webfont.woff";
import OpenSansExtraBoldItalic from "../assets/fonts/opensans-extrabolditalic-webfont.woff";
import OpenSansItalic from "../assets/fonts/opensans-italic-webfont.woff";
import OpenSansLight from "../assets/fonts/opensans-light-webfont.woff";
import OpenSansLightItalic from "../assets/fonts/opensans-lightitalic-webfont.woff";
import OpenSansRegular from "../assets/fonts/opensans-regular-webfont.woff";
import OpenSansSemiBold from "../assets/fonts/opensans-semibold-webfont.woff";
import OpenSansSemiBoldItalic from "../assets/fonts/opensans-semibolditalic-webfont.woff";
// import OpenSansFont from "./fonts/OpenSans";

const Typography = createGlobalStyle`
 
 @font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: 
    local('Open Sans Light Italic'), 
    local('OpenSans-LightItalic'), 
    url(${OpenSansLightItalic}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: 
    local('Open Sans Italic'), 
    local('OpenSans-Italic'), 
    url(${OpenSansItalic}) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: 
    local('Open Sans SemiBold Italic'), 
    local('OpenSans-SemiBoldItalic'), 
    url(${OpenSansSemiBoldItalic}) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: 
    local('Open Sans Bold Italic'), 
    local('OpenSans-BoldItalic'), 
    url(${OpenSansBoldItalic}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: 
    local('Open Sans ExtraBold Italic'), 
    local('OpenSans-ExtraBoldItalic'), 
    url(${OpenSansExtraBoldItalic}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: 
    local('Open Sans Light'), 
    local('OpenSans-Light'), 
    url(${OpenSansLight}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: 
    local('Open Sans Regular'), 
    local('OpenSans-Regular'), 
    url(${OpenSansRegular}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: 
    local('Open Sans SemiBold'), 
    local('OpenSans-SemiBold'), 
    url(${OpenSansSemiBold}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: 
    local('Open Sans Bold'), 
    local('OpenSans-Bold'), 
    url(${OpenSansBold}) format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: 
    local('Open Sans ExtraBold'), 
    local('OpenSans-ExtraBold'), 
    url(${OpenSansExtraBold}) format('woff');
}

  html {
    font-family: 'Open Sans', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    font-weight: 400;
  }
`;

export default Typography;
