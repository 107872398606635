import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    background-color: #50d8d7;
    background-image: linear-gradient( #50d8d7 0%, #ffffff 30%);


  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
  }


`;

export default GlobalStyles;
