import React from "react";
import styled from "styled-components";

const NavContainer = styled.nav`
  width: 100%;
  height: 75px;
  /* background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4); */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

const NavList = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 14px;
`;

const NavItem = styled.li`
  height: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: #000000;
    font-weight: 600;
    font-size: 20px;

    &.active {
      color: #6100ff;
    }
  }
`;

const NavLogo = styled.li`
  width: 60px;
  height: 60px;
`;

function Nav() {
  return (
    <NavContainer>
      <NavList>
        <NavLogo>
          <svg viewBox="0 0 1000 1000">
            <path
              d="M851.08 309v195.86c0 109.14-89.48 197.93-199.46 197.93a199.63 199.63 0 01-140.2-57.28A213.66 213.66 0 00559 552.84a104.54 104.54 0 0092.61 55.7c57.7 0 104.48-46.42 104.48-103.68V279.62a94.63 94.63 0 0124.73-63.92 399.6 399.6 0 00-561.66 0 94.63 94.63 0 0124.73 63.92v225.23c0 57.26 46.78 103.68 104.48 103.68 54.44 0 99.19-41.33 104.07-94.08.3-3.17-.15-16 0-19.2A196.18 196.18 0 01500 376.41a200.24 200.24 0 01239.34-49.26v121.43a104.56 104.56 0 00-87.72-47.4c-54.44 0-99.18 41.33-104.06 94.08-.29 3.17.15 16 0 19.2A196.19 196.19 0 01500 633.31a199.69 199.69 0 01-151.62 69.48c-110 0-199.46-88.79-199.46-197.93V309a397.86 397.86 0 00-48.54 191c0 220.71 178.91 399.62 399.62 399.62S899.62 720.71 899.62 500a397.86 397.86 0 00-48.54-191zM441 456.88a104.86 104.86 0 00-180.47-8.1V327.2a200.34 200.34 0 01228.06 37A213.66 213.66 0 00441 456.88z"
              fill="#ffffff"
            />
          </svg>
        </NavLogo>
      </NavList>
    </NavContainer>
  );
}

export default Nav;
