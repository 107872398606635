import React, { Suspense } from "react";
import styled from "styled-components";
import "normalize.css";

import GlobalStyles from "../../styles/GlobalStyles";
import Typography from "../../styles/Typography";

import Nav from "../Nav";

import bgImage from "../../assets/images/background-2.jpg";

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  /* background: url(${bgImage}) no-repeat #000000; */
  /* background: #265184; */
  background: #045978;
  background-size: cover;
  background-position: center top;
`;

export default function Layout({ children }) {
  return (
    <PageContainer>
      <GlobalStyles />
      <Typography />
      <Nav />
      {children}
    </PageContainer>
  );
}
